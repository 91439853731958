<template>
  <el-drawer :title='`${name}的${category}详情`' :visible.sync='visible' :close-on-press-escape='false'
             custom-class='popular-case-board-detail' :before-close='eventClose'>
    <div v-if='MapType[angle]' style='height: 100%;'>
      <el-table v-loading='loading' :data='table' style='width: 100%' height='calc(100% - 80px)' border>
        <el-table-column type='index' label='排名' min-width='80' align='center'>
          <template v-slot='{$index}'>
            <img
              v-if='$index<3'
              :src='require(`@/assets/imgs/rank-${$index+1}.png`)'
              alt=''
              style='width: 16px;'
            >
            <div v-else style='height: 24px;line-height: 24px;'>
              {{ $index > 8 ? '' : '0' }}{{ $index + 1 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop='name' :label='dimension' align='center' show-overflow-tooltip>
          <template v-slot='{row:{name,update_date}}'>
            <popular-case-tag-new :date='update_date' :text='name' />
          </template>
        </el-table-column>
        <el-table-column prop='gmv' label='GMV' width='100' align='center'>
          <template v-slot='{row:{gmv}}'>
            {{ gmv|numberFormat(2) }} 万
          </template>
        </el-table-column>
        <template v-if='dimension==="产品"'>
          <el-table-column label='客单价' width='100' align='center'>
            <template v-slot='{row:{price_per_customer}}'>
              ￥ {{ price_per_customer|numberFormat(2) }}
            </template>
          </el-table-column>
          <el-table-column label='销量' width='100' align='center'>
            <template v-slot='{row:{num_sales}}'>
              {{ num_sales|numberFormat }}
            </template>
          </el-table-column>
        </template>
        <el-table-column label='明细' min-width='500' align='center'>
          <template v-slot='{row:{children}}'>
            <el-table class='el-table__children' :data='children' style='width: 100%' :show-header='false'>
              <el-table-column prop='name' :label='MapType[angle][type]' align='center' show-overflow-tooltip>
                <template v-slot='{row:{name,update_date}}'>
                  <span v-if='name'>
                      <popular-case-tag-new :date='update_date' :text='name' />
                  </span>
                  <span v-else></span>
                </template>
              </el-table-column>
              <el-table-column align='center'>
                <template v-slot='{row:{gmv}}'>
                  <div style='line-height: 1.2;'>
                    <div>{{ gmv|numberFormat(2) }} 万</div>
                    <div style='font-size: 12px;color: #aaa'>GMV</div>
                  </div>
                </template>
              </el-table-column>
              <template v-if='MapType[angle][type].includes("产品")&&MapType[angle][type]!=="产品"'>
                <el-table-column label='客单价' width='130' align='center'>
                  <template v-slot='{row:{price_per_customer}}'>
                    <div style='line-height: 1.2;'>
                      <div>￥{{ price_per_customer|numberFormat(2) }}</div>
                      <div style='font-size: 12px;color: #aaa'>客单价</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label='销量' width='130' align='center'>
                  <template v-slot='{row:{num_sales}}'>
                    <div style='line-height: 1.2;'>
                      <div>￥{{ num_sales|numberFormat }}</div>
                      <div style='font-size: 12px;color: #aaa'>销量</div>
                    </div>
                  </template>
                </el-table-column>
              </template>
              <el-table-column align='center' min-width='300'>
                <template v-slot='{row:{children:months}}'>
                  <el-table class='el-table__children' :data='months' style='width: 100%' :show-header='false'>
                    <el-table-column prop='name' align='center' show-overflow-tooltip>
                      <template v-slot='{row:{name,update_date}}'>
                        <popular-case-tag-new :date='update_date' :text="parseTime(name + '-01','{y}年{m}月')" />
                      </template>
                    </el-table-column>
                    <el-table-column prop='gmv' label='GMV' align='center'>
                      <template v-slot='{row:{gmv}}'>
                        {{ gmv|numberFormat(2) }} 万
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class='footer'>
      <el-radio-group v-if='angle' v-model='type'>
        <el-radio-button v-for='(label,value) in MapType[angle]' :key='value' :label='value'>
          {{ label }}
        </el-radio-button>
      </el-radio-group>
    </div>
  </el-drawer>
</template>
<script>
import { boardGvmDetailThe } from '../../../../api/popular_case'
import PopularCaseTagNew from './tag-new.vue'
import { parseTime } from '../../../../utils'

const MapType = {
  BRAND: {
    BRAND_BY_PRODUCT: '产品',
    BRAND_BY_ARTIST: '红人'
  },
  ARTIST: {
    ARTIST_BY_PRODUCT: '产品',
    ARTIST_BY_BRAND: '品牌'
  },
  PLATFORM: {
    PLATFORM_BY__BRAND_ARTIST: '品牌-红人',
    PLATFORM_BY__BRAND_PRODUCT: '品牌-产品',
    PLATFORM_BY__ARTIST_BRAND: '红人-品牌',
    PLATFORM_BY__ARTIST_PRODUCT: '红人-产品'
  },
  COA_MODE: {
    COMMON_BY__BRAND_ARTIST: '品牌-红人',
    COMMON_BY__BRAND_PRODUCT: '品牌-产品',
    COMMON_BY__ARTIST_BRAND: '红人-品牌',
    COMMON_BY__ARTIST_PRODUCT: '红人-产品'
  },
  SPECIAL: {
    COMMON_BY__BRAND_ARTIST: '品牌-红人',
    COMMON_BY__BRAND_PRODUCT: '品牌-产品',
    COMMON_BY__ARTIST_BRAND: '红人-品牌',
    COMMON_BY__ARTIST_PRODUCT: '红人-产品'
  }
}
export default {
  name: 'PopularCaseBoardDetail',
  components: { PopularCaseTagNew },
  props: {
    year: [Number, String],
    month: [Number, String],
    angle: String
  },
  data() {
    return {
      MapType,
      type: 'BRAND_BY_PRODUCT',
      loading: false,
      visible: false,
      name: null,
      category: null,
      table: []
    }
  },
  computed: {
    dimension: function() {
      return this.MapType[this.angle][this.type] ? this.MapType[this.angle][this.type].split('-')[0] : ''
    }
  },
  watch: {
    type() {
      this.getData()
    }
  },
  methods: {
    parseTime,
    show: function(name, category) {
      if (!name) {
        this.$message.warning('操作失败')
        return
      }
      switch (this.angle) {
        case 'BRAND':
          this.type = 'BRAND_BY_PRODUCT'
          break
        case 'ARTIST':
          this.type = 'ARTIST_BY_PRODUCT'
          break
        case 'PLATFORM':
          this.type = 'PLATFORM_BY__BRAND_ARTIST'
          break
        case 'COA_MODE':
        case 'SPECIAL':
          this.type = 'COMMON_BY__BRAND_ARTIST'
          break
      }
      this.name = name
      this.category = category
      this.visible = true
      this.getData()
    },
    eventClose: function() {
      this.visible = false
    },
    getData: async function() {
      const params = {
        year: this.year,
        month: this.month,
        name: this.name,
        type: this.type,
        angle: this.angle,
        category: this.category === '全品类' ? '' : this.category
      }
      this.loading = true
      this.table = await boardGvmDetailThe(params)
      this.loading = false
    }
  }
}
</script>
<style lang='scss'>
.popular-case-board-detail {
  width: 100% !important;

  .el-drawer__title {
    border-top-left-radius: 10px !important;
  }

  .el-drawer__header {
    font-size: 16px !important;
    color: #333333 !important;
    font-weight: 700;
    text-align: center;
  }

  .el-drawer__body {
    border-bottom-left-radius: 10px !important;
    padding: 0 20px;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    height: 66px;
    opacity: 1;
    background: #fff;
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, .04), 0 2px 4px 0 rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 99;
  }


  .el-table__children {
    .el-table__row + .el-table__row {
      .el-table__cell {
        border-top: 1px dashed #EBEEF5 !important;
      }
    }
  }

  .el-table__children::before {
    display: none !important;
  }

  .el-table__children th.el-table__cell.is-leaf, .el-table__children td.el-table__cell {
    border-bottom: unset !important;
  }
}
</style>
