<template>
  <page-header-layout class='layout' v-loading='loading'>
    <popular-case-board-detail ref='drawer' :year='year' :month='search.month' :angle='search.angle' />
    <div class='part-top' ref='top'>
      <div style='margin-bottom: 18px;display: flex;align-items: center;justify-content: space-between;'>
        <div style='display: flex;align-items: center;'>
          <year-select-group :value.sync='year' />
          <!--    月    -->
          <el-date-picker v-model='search.month' type='month' value-format='MM' format='MM月'
                          placeholder='搜索合作月份' @change='getList' style='width: 150px;'/>
        </div>

        <div>
          <el-button v-if='hasAuthority("popular_cases_category")' type='primary'
                     @click='handleToPage("KoLPopularCaseCategory")'>分类类目管理
          </el-button>
          <el-button v-if='hasAuthority("popular_case_list")' type='primary' @click='handleToPage("KoLPopularCase")'>
            爆款案例列表
          </el-button>
        </div>
      </div>
      <div class='categories' :style='style'>
        <div class='item' :class='{active:active_category==="全品类"}' @click='active_category="全品类"'>
          <div class='name'>全品类</div>
          <div class='item-data'>
            <span>GMV</span>
            <b style='color: rgb(254, 52, 110);'>{{ total_gmv|numberFormat(2) }} 万</b>
          </div>
        </div>
        <div v-for='(i,k) in category' :key='k' class='item' :class='{active:active_category===i.name}'
             @click='active_category=i.name'
        >
          <div class='name'>
            <popular-case-tag-new :date='i.update_date' :text='i.name' />
          </div>
          <div class='item-data'>
            <span>GMV</span>
            <b style='color: rgb(254, 52, 110);'>{{ i.gmv|numberFormat(2) }} 万</b>
          </div>
        </div>
      </div>
    </div>
    <div class='part-bottom' :style='`height:calc(100% - ${diffHeight}px)`'>
      <div style='height: calc(100% - 20px);width: 50%;'>
        <div class='part-search'>
          <div class='part-search__inner'>
            <el-radio-group v-model='search.angle' @change='handleSearch'>
              <el-radio-button label='BRAND'>品牌</el-radio-button>
              <el-radio-button label='ARTIST'>红人</el-radio-button>
              <el-radio-button label='PLATFORM'>平台</el-radio-button>
              <el-radio-button label='COA_MODE'>合作形式</el-radio-button>
              <el-radio-button label='SPECIAL'>特别场</el-radio-button>
            </el-radio-group>
            <el-select v-model='search.platform_code' placeholder='搜索平台' @change='handleSearch' clearable>
              <el-option v-for='item in options.platform' :key='item.value' :label='item.label' :value='item.value' />
            </el-select>
            <el-select v-model='search.coa_mode' placeholder='搜索合作形式' @change='handleSearch' clearable>
              <el-option v-for='item in options.coa_mode' :key='item.value' :label='item.label' :value='item.value' />
            </el-select>
            <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading'
                       @click='handleSearch'>
              查询
            </el-button>
            <el-button icon='el-icon-refresh-right' :disabled='loading' :loading='loading' @click='handleReset'>
              重置
            </el-button>
          </div>
        </div>
        <el-table :data='brand' border height='calc(100% - 38px)' show-summary @row-click='eventRowClick'
                  :row-style='{cursor: "pointer"}'>
          <el-table-column type='index' label='排名' min-width='80' align='center'>
            <template v-slot='{$index}'>
              <img
                v-if='$index<3'
                :src='require(`@/assets/imgs/rank-${$index+1}.png`)'
                alt=''
                style='width: 16px;'
              >
              <div v-else style='height: 24px;line-height: 24px;'>
                {{ $index > 8 ? '' : '0' }}{{ $index + 1 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label='`${year?year+"年":""}${search.month?search.month+"月":""}${active_category||""}${angle_name||""}`' min-width='150' show-overflow-tooltip
                           align='center'>
            <template v-slot='{row:{name,update_date}}'>
              <el-link type='primary'>
                <popular-case-tag-new :date='update_date' :text='name' />
              </el-link>
            </template>
          </el-table-column>
          <el-table-column prop='gmv' label='GMV/万' min-width='100' align='center'>
            <template v-slot='{row:{gmv}}'>
              {{ gmv|numberFormat(2) }} 万
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style='height: calc(100% - 40px);width: calc(50% - 20px)'>
        <div :key='`${active_category}${angle_name}`' ref='chart' id='chart' style='height: 100%;width: 100%;' />
      </div>
    </div>
  </page-header-layout>
</template>
<script>

import { defineComponent } from 'vue'
import PageHeaderLayout from '../../../../layouts/PageHeaderLayout.vue'
import { boardGvmDetail, boardGvm, optionsPopularCases } from '../../../../api/popular_case'
import YearSelectGroup from '../../../../components/SelectGroupYear.vue'
import * as echarts from 'echarts'
import PopularCaseBoardDetail from './detail.vue'
import PopularCaseTagNew from './tag-new.vue'

const ModelSearch = {
  angle: 'BRAND',
  month: '',
  platform_code: null,
  coa_mode: null
}

const ChartOption = {
  color: ['#75A7FF', '#FF80A4'],
  title: {
    text: `GMV`,
    left: 'right',
    textStyle: {
      fontSize: 16,
      color: 'rgb(255, 90, 145)',
      fontWeight: 'bolder'
    }
  },
  tooltip: {},
  legend: {
    // left: '8%',
    type: 'scroll',  //legend要多，进行分页处理
    orient: 'vertical',
    right: '1%',
    top: '5%'
  },
  //右上角的功能小按钮
  toolbox: {
    show: false
  },
  grid: {
    left: '3%',
    top: '8%',
    containLabel: true
  },
  dataset: { source: [] },
  series: [
    {
      type: 'pie',
      radius: ['30%', '50%']
      // 数据从 dataset 中取，encode 中的数值是 dataset.source 的维度 index （即第几列）
      // encode: { itemName: 0, value: 1 }
    }
  ]
}
export default defineComponent({
  components: { PopularCaseTagNew, PopularCaseBoardDetail, YearSelectGroup, PageHeaderLayout },
  data() {
    return {
      style: 'grid-template-columns: repeat(8, calc((100% - 70px) / 8))',
      loading: false,
      year: null,
      brand: [],
      category: [],
      active_category: null,
      total_gmv: 0,
      diffHeight: 100,
      options: { platform: [], coa_mode: [] },
      search: { ...{}, ...ModelSearch }
    }
  },
  computed: {
    angle_name() {
      let name = '品牌'
      switch (this.search.angle) {
        case 'BRAND':
          name = '品牌'
          break
        case 'ARTIST':
          name = '红人'
          break
        case 'PLATFORM':
          name = '平台'
          break
        case 'COA_MODE':
          name = '合作形式'
          break
        case 'SPECIAL':
          name = '特别场'
          break
      }
      return name
    }
  },
  watch: {
    year() {
      this.getList()
    },
    active_category() {
      this.getDetail()
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.setDiffHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setDiffHeight)
  },
  created() {
    this.getOptions()
  },
  methods: {
    eventRowClick: function({ name }) {
      this.$refs.drawer.show(name, this.active_category)
    },
    handleSearch: function() {
      this.getDetail()
    },
    handleReset: function() {
      this.search = { ...{}, ...ModelSearch }
      this.handleSearch()
    },
    getOptions: async function() {
      this.options = await optionsPopularCases({ fields: ['coa_mode', 'platform'] })
    },
    setDiffHeight: function() {
      const dom = this.$refs.top
      this.diffHeight = dom.offsetHeight + 10
    },
    draw: function(source) {
      const myChart = echarts.init(document.getElementById('chart'))
      ChartOption.title.text = `${this.year?this.year+"年":""}${this.search.month?this.search.month+"月":""}${this.active_category||""}${this.angle_name||""}GVM`
      ChartOption.dataset = { source }
      myChart.setOption(ChartOption)
    },
    getList: async function() {
      this.active_category = null
      const response = await boardGvm({ year: this.year, month: this.search.month })
      this.category = Array.isArray(response) ? response : []
      const length = this.category.length + 1
      if (this.category.length > 0) {
        this.active_category = this.category[0].name
        let repeat = length
        if (length > 6) {
          repeat = parseInt((length / 2 + 1) + '')
        }
        const px = (length - 1) * 10
        this.style = `grid-template-columns: repeat(${repeat}, calc((100% - ${px}px) / ${repeat}))`
      } else {
        this.brand = []
        this.draw([])
      }
      this.total_gmv = 0
      this.category.map(_ => {
        this.total_gmv += Number(_.gmv)
      })

      this.$nextTick(() => {
        this.setDiffHeight()
      })
    },
    getDetail: async function() {
      if (!this.active_category) {
        return
      }
      const category = this.active_category === '全品类' ? null : this.active_category
      const params = { ...{ year: this.year, category }, ...this.search }
      this.loading = true
      const response = await boardGvmDetail(params)
      this.loading = false
      this.brand = Array.isArray(response) ? response : []

      const source = []
      this.brand.map(_ => {
        source.push([_.name, parseFloat((_.gmv || '0'))])
      })
      this.draw(source)
    },
    handleToPage: function(name) {
      const routeData = this.$router.resolve({ name })
      window.open(routeData.href, '_blank')
    }
  }
})
</script>
<style lang='scss'>
.KoLPopularCaseCategoryBoard_main-inner {

  font-family: FjallaOne, DINPro Medium, sans-serif !important;
  height: 100%;

  .layout {
    height: 100%;
  }

  .page-content {
    min-height: unset;
    height: calc(100% - 80px);
    background: #fff;
    padding: 20px;

    .main-page-content {
      min-height: unset;
      height: calc(100% - 40px);
    }
  }

  .categories {
    display: grid;
    grid-template-columns: repeat(8, calc((100% - 70px) / 8));
    grid-gap: 10px;
    margin-bottom: 14px;
    font-family: FjallaOne, DINPro Medium, sans-serif !important;
  }

  .item {
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 hsla(0, 0%, 92.2%, .5);
    padding: 10px;
    cursor: pointer;
    align-items: center;
    position: relative;

    div:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .name {
      font-size: 18px;
      margin-bottom: 6px;
      font-weight: 700;
    }

    .item-data {
      margin: 15px auto 0 auto;
      width: 80%;
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      font-family: FjallaOne, DINPro Medium, sans-serif !important;

      span:first-child {
        color: #8a8e99;
      }
    }
  }

  .active {
    .item-data {
      span:first-child {
        color: #1a1a1a !important;
      }
    }
  }

  .active::after {
    content: "";
    height: 3px;
    width: 80%;
    background: #ff80a4;
    border-radius: 2px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .year-select-container {
    width: 350px;
  }

  .part-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between
  }

  .part-search {
    margin-bottom: 10px;
    position: relative;
    height: 28px;

    &__inner {
      position: absolute;
      width: 200%;
      display: flex;
      align-items: center;
    }

    .el-select, .el-radio-group {
      margin-right: 10px;
    }
  }

  .el-table {
    .cell {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  #chart {
    //div:first-child {
    //  height: calc(100% - 40px) !important;
    //}
  }

  .el-table .cell {
    min-height: 30px;
  }
}

</style>
