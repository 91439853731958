<!--new标识 根据update_date七天之内显示-->
<template>
  <div style='display: inline;position: relative;'>
    {{ text }}
    <img v-if='show' src='@/assets/icons/new.png' alt='' style='width: 30px;height: 14px;' :title='`最近更新：` + date' />
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'PopularCaseTagNew',
  props: {
    date: {
      type: [String],
      default: null
    },
    text: {
      type: [String],
      default: null
    }
  },
  computed: {
    show() {
      if (!this.date) {
        return false
      }
      const days = moment().diff(moment(this.date), 'days')
      return days <= 7
    }
  }
}
</script>
<style scoped lang='scss'>
img {
  position: absolute;
  top: -4px;
}
</style>
